<template lang="pug">
.pools(
  v-infinite-scroll="fetchData"
  infinite-scroll-disabled="isPoolBusy"
  :infinite-scroll-distance="scrollDistance"
)
  Row.app-expand-tool-bar
    Button.app-tool-right(
      type="primary",
      icon="md-add",
      @click="onCreatePoolClick()"
    ) {{$t('Add game pool')}}

  Table.game-pool.app-table(:columns="poolCols", :data="poolList", border)
    template(slot-scope="{ row, index }", slot="index")
      span {{ index + 1 }}
    template(slot-scope="{ row, index }", slot="id")
      span {{ row.id }}
    template(slot-scope="{ row, index }", slot="category")
      span {{ $t(`${row.category}`) }}
    template(slot-scope="{ row, index }", slot="prize")
      span {{ currencyFormat(row.prize, { ...userInfo, digits: 2 }) }}
    template(slot-scope="{ row, index }", slot="releaseLevel")
      span {{ currencyFormat(row.releaseLevel, { ...userInfo, digits: 0 }) }}
    template(slot-scope="{ row, index }", slot="stakeRange")
      span {{ currencyFormat(row.minStake, { ...userInfo, digits: 0 }) }}
      Icon(type="md-arrow-forward", :style="{margin: '0 5px'}")
      span {{ currencyFormat(row.maxStake, { ...userInfo, digits: 0 }) }}
    template(slot-scope="{ row, index }", slot="initialLevel")
      span {{ currencyFormat(row.initialLevel, { ...userInfo, digits: 0 }) }}
    template(slot-scope="{ row, index }", slot="gameClass")
      span {{ $t(`class${row.gameClass}`) }}
    template(slot-scope="{ row, index }" slot="actions" v-if="userInfo.isAdministrator")
      //- Tooltip(placement="top", :content="$t('Edit pool')")
      Button(
        type="success",
        size='small',
        icon="md-create",
        @click="editPool(row)"
      )
  Col.table-loading-col(span='24', v-if="poolListLoading")
    Spin(fix, :style="{ backgroundColor: 'transparent', marginTop: '0' }")
      Icon(type="ios-loading" size=18 class="spin-icon")

  PoolEditor(
    :form-data="poolData2Update",
    :edit-type="poolEditType"
    :on-update="onPoolUpdate"
    :visible="editorVisible"
    :on-close="closePoolEditor"
  )

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Currency from '~m/currency'
import Scroll from '~m/scroll'
import NumberUtil from '~m/number-util'
import PoolEditor from '~c/pool-editor'
import to from 'await-to-js'

export default {
  name: 'game-pools',
  data () {
    return {
      poolData2Update: null,
      editorVisible: false,
      poolEditType: 'edit',
      gameClassList: [
        {
          label: this.$t('class0'),
          value: '0'
        },
        {
          label: this.$t('class1'),
          value: '1'
        },
        {
          label: this.$t('class2'),
          value: '2'
        },
        {
          label: this.$t('class3'),
          value: '3'
        },
        {
          label: this.$t('class4'),
          value: '4'
        }
      ]
    }
  },
  props: {
    gameData: Object
  },
  mixins: [Currency, Scroll, NumberUtil],
  components: { PoolEditor },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['userInfo']),
    ...mapGetters('pool', { poolList: 'getListData', poolOne: 'getOneData', poolListLoading: 'getLoadingStatus', hasNextPool: 'hasNext' }),
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),
    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },
    gameClass () {
      let { gameClass } = this.$route.query
      if (!gameClass) gameClass = '0'
      return gameClass
    },
    poolCols () {
      return [
        {
          slot: 'index',
          width: 75,
          align: 'center'
        },
        {
          title: 'ID',
          slot: 'id',
          width: 75,
          align: 'center'
        },
        {
          title: this.$t('Pool type'),
          slot: 'category',
          width: 100
        },
        {
          title: this.$t('Pool prize'),
          slot: 'prize'
        },
        {
          title: this.$t('Pool release amount'),
          slot: 'releaseLevel',
          width: 150
        },
        {
          title: this.$t('Pool stake range'),
          slot: 'stakeRange'
        },
        {
          title: this.$t('Pool initial level'),
          slot: 'initialLevel',
          width: 150
        },
        {
          title: this.$t('Game class'),
          slot: 'gameClass',
          width: 150
        },
        {
          title: this.$t('Pool switch'),
          key: 'actived',
          // 無法用 slot template 取得點擊後的 instance 用 render 取代
          render: this.renderSwitch,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 66
        }
      ]
    },
    isPoolBusy () {
      return this.poolListLoading || !this.hasNextPool
    }
  },
  methods: {
    ...mapActions('pool', { updatePool: 'update', findPools: 'find', resetPool: 'resetList', createPool: 'create' }),
    // 動態 switch 無法用 slot 模式嵌入 table row 中，以 render 方式實做
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('pool/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },

    fetchData () {
      const { gameId, providerId } = this.gameData
      const { operator = 106, gameClass = '0' } = this.$route.query
      // this.gameClass = gameClass
      const query = { operator, game: gameId, provider: providerId, category: 'jp4,jp,jps,jpm,jpl', gameClass }
      this.findPools(query)
    },

    editPool (row) {
      this.poolData2Update = _.cloneDeep(row)
      this.poolEditType = 'edit'
      this.editorVisible = true
      console.log('this.editorVisible', this.editorVisible, this.poolData2Update)
    },

    onCreatePoolClick () {
      const { provider, providerId } = this.gameData
      let { operator = 106 } = this.$route.query
      operator = _.find(this.allOperators, { id: parseInt(operator, 10) })
      this.poolData2Update = {
        game: this.gameData,
        gameId: this.gameData.gameId,
        minStake: 1,
        maxStake: 99999,
        releaseLevel: 0,
        odds: 0,
        category: 'jp',
        initialLevel: 0,
        actived: true,
        prize: 0,
        operator,
        provider,
        providerId,
        gameClass: this.gameClass,
        isReady: false,
        isDirty: false
      }

      this.poolEditType = 'new'
      this.editorVisible = true
    },

    async onPoolUpdate () {
      if (!this.poolData2Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closePoolEditor()
      }

      let dataUpdate = _.pick(this.poolData2Update, ['minStake', 'maxStake', 'releaseLevel', 'odds', 'category', 'initialLevel', 'actived', 'prize', 'gameClass'])

      // 強轉數字類型
      this.parseObjectNumber(dataUpdate)
      dataUpdate.gameClass = `${dataUpdate.gameClass}`
      dataUpdate = { data: dataUpdate }

      let method
      switch (this.poolEditType) {
        case 'edit':
          method = this.updatePool
          dataUpdate.id = this.poolData2Update.poolId
          break

        case 'new':
          const { gameId, _id, provider, providerId } = this.gameData
          let { operator = 106 } = this.$route.query
          operator = _.find(this.allOperators, { id: parseInt(operator) })
          dataUpdate.data = {
            ...dataUpdate.data,
            gameId,
            game: _id,
            provider,
            providerId,
            operatorId: operator.id,
            operator: operator._id
          }
          console.log('this.gameData', this.gameData)
          console.log(dataUpdate.data)
          method = this.createPool
          break
      }

      let [err] = await to(method(dataUpdate))
      if (err) {
        console.log(err)
        const msg = this.poolEditType === 'edit' ? 'Edit pool error' : 'Create pool error'
        return this.$Message.error(this.$t(msg))
      }

      this.$Message.success(this.$t('Edit pool seccess'))
      this.closePoolEditor()
      // this.$forceUpdate()
      if (this.poolEditType === 'new') {
        await this.resetPool()
        this.fetchData(true)
      }
    },

    closePoolEditor () {
      this.editorVisible = false
      setTimeout(() => {
        this.poolData2Update = null
      }, 500)
    }
  },

  mounted () {
    this.fetchData()
  },

  beforeDestroy () {
    this.resetPool()
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
</style>
