<template lang="pug">
div
  .table
    AppTable.app-table(:columns="columns", :data="listData", border)
      template(
        slot-scope="{ row, index }"
        slot="info"
      )
        Avatar(:src="getGameThumb(row, userInfo)", shape="square", size='small', :style="{marginRight: '8px'}")
        span.pointer(
          @click="searchGame(row.name)"
        ) {{ row.name }}
      template(
        slot-scope="{ row, index }"
        slot="code"
      )
        span.pointer(
          @click="searchGame(row.code)"
        ) {{ row.code }}
      template(
        slot-scope="{ row, index }"
        slot="mechanism"
      )
        span {{ $t(row.mechanism) }}

      template(
        slot-scope="{ row, index }"
        slot="rtp"
      )
        span.current-rtp {{ getRTP(row) }}
        span.rtp-divider /
        span.target-rtp {{ row.config.GameRate * 100 }}%
      template(
        slot-scope="{ row, index }"
        slot="jackpot"
      )
        span {{ getGameJackpot(row) }}

      template(
        slot-scope="{ row, index }"
        slot="actions"
      )
        Button(
          type="success",
          size='small',
          icon="md-create",
          v-if="functions.editButton"
          @click="editGame(row)"
        )

  GameEditor(
    v-if="functions.editButton"
    :login-user-info="userInfo",
    :form-data="gameData4Update",
    :edit-type="gameEditorType"
    :on-update="onGameUpdate"
    :visible="gameEditorVisible"
    :on-close="closeGameEditor"
  )

</template>
<script>
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'
import GamePoolList from '~v/game/game-pools'
import AppTable from '~c/app-table'
import GameEditor from '~c/game-editor'
import { mapActions, mapGetters } from 'vuex'
import to from 'await-to-js'

export default {
  name: 'game-table',
  mixins: [
    Currency,
    Thumbnail,
    Time
  ],
  components: {
    GamePoolList,
    AppTable,
    GameEditor
  },
  data () {
    return {
      gameData4Update: {},
      gameEditorType: null,
      gameEditorVisible: false,
      gameSyncInfoId: null
    }
  },
  props: {
    listData: Array
  },
  watch: {
    selectedGame (nv, ov) {
      if (!nv) return
      const { totalIn, totalOut, levelInOut } = nv
      this.gameData4Update = {
        ...this.gameData4Update,
        totalIn,
        totalOut,
        levelInOut
      }
    }
  },
  computed: {
    ...mapGetters('game', {
      selectedGame: 'getOneData'
    }),
    ...mapGetters('auth', ['userInfo']),
    hasOperator () {
      return /^\d+$/.test(this.$route.query.operator)
    },

    functions () {
      return this.userInfo.sidebarMap.game.functions
    },

    // 遊戲表單
    columns () {
      let columns = [
        {
          title: 'ID',
          key: 'gameId',
          width: 64,
          align: 'center',
          fixed: window.innerWidth <= 400 ? 'left' : ''
        },
        // {
        //   title: this.$t('Created at'),
        //   slot: 'createdAt',
        //   align: 'center',
        //   ellipsis: true
        // },
        {
          title: this.$t('Game info'),
          slot: 'info',
          width: 150,
          ellipsis: true
        },
        {
          title: this.$t('Game code'),
          slot: 'code',
          minWidth: 120
        },
        {
          title: this.$t('Game mechanism'),
          slot: 'mechanism',
          minWidth: 120
        },
        {
          title: `${this.$t('Current / Target RTP')}`,
          slot: 'rtp',
          minWidth: 120
        },
        {
          title: this.$t('Version'),
          key: 'version',
          align: 'center',
          width: 135
        },
        {
          title: this.$t('Game jackpot'),
          slot: 'jackpot'
        },
        // {
        //   title: this.$t('Hot'),
        //   render: this.renderSwitchHot,
        //   align: 'center',
        //   width: 85
        // },
        {
          title: this.$t('Gem'),
          render: this.renderSwitchGem,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Actived'),
          key: 'actived',
          render: this.renderSwitch,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Test'),
          key: 'test',
          render: this.renderSwitchTest,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 66
        },
        // {
        //   slot: 'expand',
        //   width: 50
        // },
        {
          type: 'expand',
          width: 50,
          // on: {
          //   click: (evt) => {
          //     console.log(`click`, evt)
          //   }
          // }
          render: (h, params) => {
            return h(GamePoolList, {
              props: {
                gameData: params.row
              }
            })
          }
        }
      ]

      if (!this.hasOperator) {
        columns = _.reject(columns, c => {
          return c.slot === 'jackpot' || c.type === 'expand' || c.slot === 'expand'
        })
      }

      return columns
    }
  },
  mounted () {
    //
  },
  methods: {
    ...mapActions('game', {
      gameUpdate: 'update',
      findGame: 'findOne'
    }),

    async onGameUpdate () {
      if (!this.gameData4Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeGameEditor()
      }

      let dataUpdate = _.pick(this.gameData4Update, ['prizeLimit', 'tags', 'ratioValues', 'stakeValues', 'config', 'mgGroupBoxChoiceVal'])
      let [err] = await to(this.gameUpdate({ id: this.gameData4Update.gameId, data: dataUpdate }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Edit fail'))
      }

      this.$Message.success(this.$t('Edit success'))
      this.closeGameEditor()
    },

    async editGame (row) {
      this.gameEditorType = 'edit'
      this.gameEditorVisible = true
      this.gameData4Update = _.cloneDeep(row)
      this.syncGameInfo()
    },

    syncGameInfo () {
      this.gameSyncInfoId = setInterval(() => {
        this.findGame({ id: this.gameData4Update.id })
      }, 1000)
    },

    closeGameEditor () {
      this.gameEditorVisible = false
      clearInterval(this.gameSyncInfoId)
      setTimeout(() => {
        this.gameEditorType = null
        this.gameData4Update = null
      }, 500)
    },

    // 計算每筆水池資料的 RTP
    getRTP (row) {
      const { totalIn, totalOut } = row
      return `${_.floor(totalOut / totalIn * 100, 2)}%`
    },

    // 從資料的 pools 中取得遊戲水池
    getGameJackpot (row) {
      const jp = _.find(row.pools, { category: 'jp4' })
      if (jp) return this.currencyFormat(jp.prize, this.userInfo)
      return this.$t('No game jackpot')
    },

    // 開關 jackpot 的子 表單
    toggleJackpot (idx) {
      if (this.jackpotIndex === idx) {
        this.jackpotIndex = null
        return
      }
      this.jackpotIndex = idx
    },
    renderSwitchGem (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.config.gemSystemOn === 1 || row.config.gemSystemOn === true,
          disabled: !this.functions.editButton
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                config: {
                  ...row.config,
                  gemSystemOn: val
                }
              }
              dispatch('game/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived,
          disabled: !this.functions.editButton
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('game/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitchTest (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.test,
          disabled: !this.functions.editButton
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                test: !row.test
              }
              dispatch('game/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitchHot (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.isHot,
          disabled: !this.functions.editButton
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                isHot: !row.isHot
              }
              dispatch('game/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    searchGame (name) {
      this.$emit('after-click-name', name)
    }
  }
}
</script>
<style lang="sass" scoped>
.current-rtp
  // font-weight: bold
  color: #ed4014

.target-rtp
  color: #c5c8ce

.rtp-divider
  margin: 0 4px
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
