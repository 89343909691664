<template lang="pug">
.content(
  v-infinite-scroll="fetchData"
  infinite-scroll-disabled="isUserBusy"
  :infinite-scroll-distance="scrollDistance"
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Affix(
      :offset-top="62"
      @on-change="searchAffixChange"
      :class="affixClass"
    )
      Row
        Col(span='24')
          Select.app-tool(
            v-model="category"
            :disabled="gameListLoading"
            style="width:140px"
            prefix="ios-cube"
            @on-change="onChangeGameType"
            filterable
          )
            Option(v-for="item in gameCategoryList" :value="item.value" :key="item.value") {{ item.label }}

          //- 廳別
          .gameClass(v-if="hasOperator")
            Select.app-tool(
              v-model="gameClass"
              :disabled="gameListLoading"
              style="width:140px"
              prefix="ios-cube-outline"
              v-if="isCasino"
              @on-change="onGameClassChange"
              filterable
            )
              Option(v-for="item in gameClassList" :value="item.value" :key="item.value") {{ item.label }}

          Input.app-tool(
            v-model="search"
            :disabled="poolOneLoading"
            icon="ios-search"
            clearable
            :placeholder="$t('Search game name')"
            @on-change='onSearchInputChange'
          )

          Button.app-tool(
            v-if="userInfo.isAdministrator && functions.addButton"
            icon="md-add"
            type="primary"
            @click="editGame()"
            style="float: right;"
          ) {{$t('Add new game')}}

    //- 有選擇 operator 時出現的水池內容
    //- Row(:style="{marginTop: '16px'}" v-if="hasOperator")
    //-   Col(span='24')
    //-     GameLobbyPool(
    //-       :pool-data="lobbyPoolData"
    //-       :operator-name="selectedOperator.label"
    //-       :user-info="userInfo"
    //-     )
    //-   Col(span='24', class="table-loading-col" v-if="poolOneLoading")
    //-     Spin(fix)
    //-       Icon(type="ios-loading" size=18 class="spin-icon")

    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24', v-if="gameList.length || !gameListLoading")
        GameTable(
          :list-data="gameList"
          :has-operator="hasOperator"
          :user-info="userInfo"
          @after-click-name="afterClickName"
        )

      Col(span='24', class="table-loading-col" v-if="gameListLoading")
        Spin(fix)
          Icon(type="ios-loading" size=18 class="spin-icon")

  GameEditor(
    :visible="gameEditorVisible"
    :login-user-info="userInfo"
    :form-data="gameData4Update"
    :edit-type="gameEditorType"
    :on-update="onGameUpdate"
    :on-close="closeGameEditor"
  )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GameLobbyPool from '~c/game-lobby-pool'
import GameTable from '~v/game/game-table'
import GameEditor from '~c/game-editor'
import RouterEvents from '~m/router-events'
import Scroll from '~m/scroll'
import to from 'await-to-js'

export default {
  name: 'game',
  components: {
    GameLobbyPool,
    GameTable,
    GameEditor
  },

  mixins: [Scroll, RouterEvents],

  // view 裡大部份的 data 是用來做過濾器用的，主要是同步 url 上的參數，部份 data 是做過濾器的暫存
  data () {
    return {
      operator: '',
      provider: '',
      category: 'all',
      gameClass: '0',
      search: '',
      operatorOptions: [],
      jackpotIndex: null,
      delaySearch: null,
      // game editor 用參數
      gameData4Update: null,
      gameEditorType: null,
      gameEditorVisible: false,
      // 初始的 operator
      defaultOperators: {
        value: 'all',
        label: this.$t('All operators')
      },
      isCasino: false
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      if (nv.params.provider === ov.params.provider) return this.fetchData(true)
      // pid 不一樣要更新 operator list
      await this.resetSelector()
    },

    lobbyPoolData (nv, ov) {
      this.isCasino = nv.operator && nv.operator.isCasino ? nv.operator.isCasino : false
    }
  },

  // 所有內容的多數 data 來自於 vuex 的 state 以 module 裡的 getter 的方式嵌入 computed 中
  computed: {
    // 嵌入遊戲的 state 資料
    ...mapGetters('game', {
      gameList: 'getListData',
      gameListLoading: 'getLoadingStatus',
      hasNextGame: 'hasNext'
    }),

    // 嵌入彩金池的 state 資料
    ...mapGetters('pool', {
      poolList: 'getListData',
      poolOne: 'getOneData',
      poolListLoading: 'getLoadingStatus',
      poolOneLoading: 'getLoadingOneStatus',
      hasNextPool: 'hasNext'
    }),

    functions () {
      return this.userInfo.sidebarMap.game.functions
    },

    // 大聽彩池資料，由於送出的 pool query 只取 jp5，只取第一筆為大廳彩池
    lobbyPoolData () {
      return this.poolOne || {}
    },

    // 遊戲內容是否在讀取
    isGameBusy () {
      return this.gameListLoading || !this.hasNextGame
    },

    isPoolOneBusy () {
      return this.poolOneLoading
    },

    // 是於有設定 operator 過濾
    hasOperator () {
      return /[0-9]+/.test(`${this.operator}`)
    }
  },

  methods: {
    ...mapActions('game', { findGame: 'find', resetGame: 'reset', createGame: 'create' }),
    ...mapActions('pool', { findPoolOne: 'findOne', findPoolList: 'find', resetPool: 'reset' }),

    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { query, params } = this.$route
      let { search, operator, category, gameClass } = query
      if (this.selectedOperator && !operator) {
        return this.onOperatorChange(this.selectedOperator.id)
      }
      operator = operator === 'all' ? '' : operator
      category = category === 'all' ? '' : category
      if (operator) gameClass = gameClass === undefined ? this.gameClass : gameClass
      let provider = params.provider
      search = search === '' ? null : search
      if (reset) {
        this.resetGame({ operator, provider, category, search })
        this.resetPool()
      }

      if (this.isGameBusy) return
      gameClass = gameClass || '0'
      this.findGame({ operator, provider, category, search })
      if (!this.isPoolOneBusy) this.findPoolOne({ category: 'jp5', operator, provider, gameClass })
    },

    // 依據 route 重設所有過濾條件
    async resetSelector () {
      let { search, category, gameClass } = this.$route.query
      this.provider = this.$route.params.provider * 1
      this.category = _.isUndefined(category) ? 'all' : category
      this.gameClass = _.isUndefined(gameClass) ? '0' : gameClass
      this.search = search || ''
      this.fetchData(true)
    },

    async onGameUpdate () {
      if (!this.gameData4Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeGameEditor()
      }

      // this.$Message.success(this.$t('profile is modify'))
      let [err] = await to(this.createGame({ data: this.gameData4Update }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Create game error'))
      }

      this.$Message.success(this.$t('Create game successful'))
      this.closeGameEditor()

      this.fetchData(true)
    },

    closeGameEditor () {
      this.gameEditorVisible = false
      setTimeout(() => {
        this.gameEditorType = null
        this.gameData4Update = null
      }, 500)
    },

    async editGame () {
      this.gameEditorType = 'new'
      this.gameEditorVisible = true
      this.gameData4Update = {
        name: '',
        code: '',
        mechanism: 'slot1',
        category: 'slot',
        providerId: this.$route.params.provider
      }
    },
    afterClickName (name) {
      if (this.search !== name) {
        this.search = name
        this.onSearchInputChange()
      }
    }
  },
  async mounted () {
    await this.resetSelector()
  },
  beforeDestroy () {
    this.resetGame()
    this.resetPool()
  }
}

</script>

<style lang="sass">
</style>
