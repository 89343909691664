<script>
/* eslint-disable */
const thumbPath = process.env.VUE_APP_GAME_THUMBNAIL.replace(/{/g, '${')
const symbolPath = process.env.VUE_APP_GAME_SYMBOL.replace(/{/g, '${')
const goodsPath = process.env.VUE_APP_GOODS_IMAGE.replace(/{/g, '${')
const avatarsPath = process.env.VUE_APP_AVATARS_IMAGE.replace(/{/g, '${')
const avatarsFramePath = process.env.VUE_APP_AVATARS_FRAME_IMAGE.replace(/{/g, '${')
const playUrl = process.env.VUE_APP_PLAY_URL

// 解析縮圖資料 base url 來源自 .env
const typeMapping = {
  'effect': 'effects',
  'avatar': 'avatars',
  'avatarframe': 'frames',
  'tile': 'tiles',
  'table': 'tables',
  'hand': 'hands',
  'accessory': 'accessories',
  'animation': 'animations',
  'emoji': 'emojis',
  'theme': 'themes',
  'gesture': 'gestures'
}
export default {
  methods: {
    // 取得盤面 icon
    getSymbol (game, symbol) {
      if (!game) return null
      let symbolUrl = ''
      const { checksum, code } = game
      eval('symbolUrl=`' + symbolPath + '`')
      return symbolUrl
    },

    // 取得遊戲縮圖
    getGameThumb (game = {}, userInfo = {}) {
      if (!game && !userInfo) return null
      const { locale = 'zh-tw' } = userInfo
      const { checksum = 'unknown', code = 'unknown' } = game

      let thumbThumb = ''
      eval('thumbThumb=`' + thumbPath + '`')
      return thumbThumb
    },

    getGoodsThumb (goods) {
      if (!goods) return null
      let imageUrl = ''
      const { code, _id } = goods
      eval('imageUrl=`' + goodsPath + '`')
      return imageUrl
    },

    getAvatar (index) {
      if (index === undefined) return null
      let imageUrl = ''
      eval('imageUrl=`' + avatarsPath + '`')
      return imageUrl
    },

    getHand (id) {
      if (id === undefined) return null
      return `${playUrl}images/hands/${id}.png`
    },
    getEffect (id) {
      if (id === undefined) return null
      return `${playUrl}images/effects/${id}.png`
    },
    getTable (id) {
      if (id === undefined) return null
      return `${playUrl}images/tables/${id}.png`
    },
    getTile (id) {
      if (id === undefined) return null
      return `${playUrl}images/tiles/${id}.png`
    },
    getAccessory (id) {
      if (id === undefined) return null
      return `${playUrl}images/accessories/${id}.png`
    },
    getMetaThumbnail (id, type) {
      if (id === undefined) return null
      return `${playUrl}images/${typeMapping[type]}/${id}.png`
    },

    getAvatarFrame (index) {
      if (index === undefined) return null
      let imageUrl = ''
      eval('imageUrl=`' + avatarsFramePath + '`')
      return imageUrl
    }
  }
}
</script>
