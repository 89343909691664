<script>
import to from 'await-to-js'
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      winBox4Edit: null,
      winBoxEditorVisible: false,
      winBox4TemplateEdit: null,
      winBoxTemplateVisible: false,
      TemplateEditorVisible: false,

      winBox4Temp: null,
      rateMetaList: null,
      rateMetaTempalteEdite: null, // 模板
      modalIs: '',
      templateEditDate: {
        data: []
      },
      winBoxPickedKeys: ['minStake', 'maxStake', 'releaseLevel', 'odds', 'category', 'initialLevel', 'actived', 'prize', 'slotTablesCount', 'poolRate', 'fgMiniWinRate', 'fgEnterRate', 'fgMaxEnterRate', 'mgHighWinChance', 'mgMiniWinRate', 'mgNoWinChance', 'mgNoWinChanceLimit', 'rtpControl', 'fgWinTolerance', 'mgWinTolerance', 'mgNormalBox', 'mgWinBox', 'fgWinBox', 'jpDrawRate', 'mgWinCoolingTime', 'fgWinCoolingTime', 'jpWinCoolingTime', 'coolingMode', 'jpProfitDay', 'mgAddFakeTimesRateInNoWin', 'mgAddFakeScatterRateInNoWin', 'fgAddFakeTimesRateInNoWin', 'fgAddFakeScatterRateInNoWin', 'firstViewExcludeTimesRate', 'timesSymbolAmountLimit', 'rareTimesSymbolRate', 'mgHitBoxes', 'fgHitBoxes', 'insurancePrize', 'mgNormalBoxValues', 'mgWinBoxValues', 'fgWinBoxValues', 'operatorProfitHour', 'autoConfirmTime', 'mechanism', 'rareGreenTimesSymbolRate', 'rareBlueTimesSymbolRate', 'rarePurpleTimesSymbolRate', 'rareRedTimesSymbolRate', 'drawRate'
      ]
    }
  },
  methods: {
    ...mapActions('operator', {
      updateOperator: 'setOne'
    }),

    async onWinBoxUpdate () {
      console.log('--- onWinBoxUpdate ---')
      if (!this.winBox4Edit.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.onWinBoxClose()
      }
      console.log(this.winBox4Edit.type)
      if (this.winBox4Edit.type === 'game') {
        const dataUpdate = _.pick(this.winBox4Edit, this.winBoxPickedKeys)
        const [err] = await to(this.updateOperatorGame({
          id: this.winBox4Edit.id,
          data: dataUpdate
        }))
        if (err) {
          console.log(err)
          return this.$Message.error(this.$t('Edit Operator Game fail'))
        }
      } else if (this.winBox4Edit.type === 'pool') {
        const dataUpdate = _.pick(this.winBox4Edit, this.winBoxPickedKeys)

        // 強轉數字類型
        this.parseObjectNumber(dataUpdate)

        dataUpdate.mechanism = this.mechanism
        const [err] = await to(this.updatePool({ id: this.winBox4Edit.id, data: dataUpdate }))
        if (err) {
          console.log(err)
          return this.$Message.error(this.$t('Edit Pool fail'))
        }
      }

      this.$Message.success(this.$t('Edit success'))
      // if (this.winBox4Edit.type !== 'game') window.location.reload()
    },
    useTemplateUpdate (editTemp) {
      const properties = [
        'fgEnterRate',
        'fgMaxEnterRate',
        'fgMiniWinRate',
        'fgWinTolerance',
        'mgHighWinChance',
        'mgMiniWinRate',
        'mgNoWinChance',
        'mgNoWinChanceLimit',
        'mgWinTolerance',
        'poolRate'
        // 'rtpControl'
      ]

      let { fgWinBoxValues, mgNormalBoxValues, mgWinBoxValues } = editTemp.data.winBox

      let same = _.isEqual(this.winBox4Temp.fgWinBoxValues, fgWinBoxValues) &&
        _.isEqual(this.winBox4Temp.mgNormalBoxValues, mgNormalBoxValues) &&
        _.isEqual(this.winBox4Temp.mgWinBoxValues, mgWinBoxValues)

      let poorSame = properties.every(prop => this.winBox4Temp[prop] === editTemp.rtpRate[0][prop])

      if (same && poorSame) {
        return this.$Message.success(this.$t('Data no change'))
      } else {
        this.winBox4Temp.isDirty = true
        this.winBox4Temp.type = 'pool'
      }

      const sourceProperties = _.pick(editTemp.rtpRate[0], properties)
      _.assign(this.winBox4Temp, sourceProperties)

      this.winBox4Edit = {
        ...this.winBox4Temp,
        fgWinBoxValues: fgWinBoxValues,
        mgNormalBoxValues: mgNormalBoxValues,
        mgWinBoxValues: mgWinBoxValues
      }
      this.$Message.success(this.$t('Edit success'))
      this.onWinBoxUpdate()
    },
    onWinBoxClose () {
      this.winBoxEditorVisible = false
      // if (this.winBox4Edit.type === 'pool' && this.winBox4Edit.isDirty) this.closePoolEditor()
    },
    winBoxTemplateClose () {
      this.winBoxTemplateVisible = false
    },
    winBoxTemplateEditorClose () {
      this.TemplateEditorVisible = false
    },
    openTemplateEditor (row) {
      this.templateEditDate = { ...row }
      this.TemplateEditorVisible = true
    },
    openWinBoxTemplate (page) {
      if (page === 'template') {
        this.winBoxTemplateVisible = true
      } else {
        if (this.modalIs === 'adjust') {
          this.winBoxEditorVisible = true
        } else {
          this.winBoxTemplateVisible = true
        }
      }
    }
  }
}
</script>
