<template lang="pug">
Card.app-info-card(dis-hover)
  h3(slot="title")
    span {{ $t('Lobby pool') }}
    .pull-right
      Tooltip(
        placement="top"
        :content="$t('Other settings')"
      )
        Button.actions(
          type="success",
          size='small',
          icon="md-create",
          @click="editPool"
        )

  Row.info-card-body
    Col.card-cell(:lg="12" :md="24")
      Row.card-row
        Col.card-col(span="12")
          span {{ $t('Pool current prize') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ currency(formData.prize || '0') }}
      Row.card-row
        Col.card-col(span="12")
          span {{ $t('Pool bet count') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ numberWithCommas(formData.betCount) }}
      Row.card-row
        Col.card-col(span="12")
          span {{ $t('Pool release amount') }} / {{ $t('Pool initial level') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ currency(formData.releaseLevel) }} / {{ currency(`${formData.initialLevel}`) }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Main game win cooling time') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.mgWinCoolingTime }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Main game mini win rate') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.mgMiniWinRate || '10' }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Main game high win chance') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.mgHighWinChance || '0' }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Main game not win chance') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.mgNoWinChance || '0' }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Free game enter rate') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.fgEnterRate || '0.001' }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Free game mini win rate') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.fgMiniWinRate || '10' }}

    Col.card-cell(:lg="12" :md="24")
      Row.card-row
        Col.card-col(span="12")
          span {{ $t('Pool stake range') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ numberWithCommas(formData.minStake) }}
          Icon(type="md-arrow-forward", :style="{margin: '0 5px'}")
          span {{ numberWithCommas(formData.maxStake) }}

      Row.card-row
        Col.card-col(span="12")
          span {{ $t('Pool switch') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.actived ? $t('Open') : $t('Close') }}

      //- Row.card-row(v-if="mechanism && mechanism !== 'all'")
      //-   Col.card-col(span="12")
      //-     span {{ $t('RTP control') }}
      //-   Col.card-row-align-right.card-col(span="12")
      //-     span {{ formData.rtpControl !== false ? $t('Open') : $t('Close') }}

      Row.card-row(v-if="mechanism && mechanism !== 'all'")
        Col.card-col(span="12")
          span {{ $t('Pool fill rate') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.poolRate || '0' }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Jackpot win cooling time') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.jpWinCoolingTime }}

      Row.card-row(v-if="mechanism && mechanism !== 'all'")
        Col.card-col(span="12")
          span {{ $t('Cooling mode') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ $t(`Cooling ${formData.coolingMode || 'share'}`) }}

      Row.card-row(v-if="mechanism && mechanism !== 'all'")
        Col.card-col(span="12")
          span {{ $t('Main game max win rate') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.mgWinTolerance || 150 }}

      Row.card-row(v-if="mechanism && mechanism !== 'all'")
        Col.card-col(span="12")
          span {{ $t('Main game not win chance limit') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.mgNoWinChanceLimit || '0' }}

      Row.card-row(v-if="mechanism && mechanism !== 'all'")
        Col.card-col(span="12")
          span {{ $t('Free game max enter rate') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.fgMaxEnterRate || '0.001' }}

      Row.card-row(v-if="mechanism && mechanism !== 'all'")
        Col.card-col(span="12")
          span {{ $t('Free game max win rate') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.fgWinTolerance || 300 }}

      Row.card-row(v-if="!isAllMechanism")
        Col.card-col(span="12")
          span {{ $t('Free game win cooling time') }}
        Col.card-row-align-right.card-col(span="12")
          span {{ formData.fgWinCoolingTime }}

  Modal(
    v-model="poolCloseModal"
    :title="$t('Pool switch')"
    @on-ok="closeAllPools()"
  )
    p {{ $t('Are you sure to close all pools')}}

  PoolEditor(
    :form-data="poolData4Update",
    edit-type="edit"
    :on-update="onPoolUpdate"
    :visible="poolEditorVisible"
    :on-close="closePoolEditor"
    :mechanism="mechanism"
    :open-template-edit="openPoolTemplateEdit"
    :is-all-mechanism="isAllMechanism"
  )

</template>
<script>
import Currency from '~m/currency'
import NumberUtil from '~m/number-util'
import WinBox from '~m/win-box'
import PoolEditor from '~c/pool-editor'
import { mapActions } from 'vuex'
import to from 'await-to-js'

export default {
  name: 'game-lobby-pool',
  data () {
    return {
      metaReplicatorVisible: false,
      poolData4Update: null,
      operators4Replace: null,
      dataUpdate: {},
      poolEditorVisible: false,
      poolTemplateVisible: false,
      poolTemplateEditVisible: false,
      poolDataBag: null,
      winBox4Edit: null,
      winBoxTmp4Edit: null,
      templateList: [],
      poolCloseModal: false,
      operators4Update: []
    }
  },
  components: {
    PoolEditor
  },
  props: {
    poolData: Object,
    operatorName: String,
    userInfo: Object,
    onUpdate: Function,
    mechanism: String,
    refresh: Function
  },

  mixins: [Currency, NumberUtil, WinBox],
  computed: {
    isAllMechanism () {
      return !this.mechanism || this.mechanism === '' || this.mechanism === 'all'
    },
    gameClass () {
      let { gameClass } = this.$route.query
      if (!gameClass) gameClass = '0'
      return gameClass
    },
    formData () {
      return {
        ...this.dataUpdate,
        ...this.poolData
      }
    }
  },

  methods: {
    ...mapActions('pool', {
      closeAllPool: 'updateAll',
      updatePool: 'update',
      findPool: 'findOne'
    }),

    ...mapActions('opeartor', {
      findOperator: 'findOne'
    }),

    ...mapActions('operatorGame', {
      cloneOperatorMeta: 'create'
    }),

    ...mapActions('distTemplates', ['getList']),

    cloneOperatorGame () {
      const { operator: sourceOperator, mechanism } = this.$route.query
      this.operators4Replace = {
        operators: [],
        sourceOperator,
        mechanism
      }
      this.metaReplicatorVisible = true
    },

    async onCloneOperatorGame () {
      if (!this.operators4Replace.operators) {
        return this.$Message.success(this.$t('No operator selected'))
      }

      await this.cloneOperatorMeta({ data: this.operators4Replace })
      this.$Message.success(this.$t('Clone operator game successful'))
    },

    onGameMetaClose () {
      this.metaReplicatorVisible = false
    },

    currency (number = '0', opts) {
      if (!number) return '0'
      opts = _.merge(opts, this.userInfo)
      return this.currencyFormat(number, opts)
    },

    editPool () {
      this.poolData4Update = {
        drawRate: 0,
        ...this.dataUpdate,
        ...this.poolData
      }
      delete this.poolData4Update.slotTablesCount
      this.poolEditorVisible = true
    },

    editWinBox () {
      this.winBox4Edit = {
        ...this.dataUpdate,
        ...this.poolData,
        type: 'pool'
      }

      this.winBoxEditorVisible = true
    },

    async getWinBoxList (row) {
      // this.winBoxTmp4Edit = _.cloneDeep(this.formData)
      this.winBox4Temp = {
        ...this.dataUpdate,
        ...this.poolData
      }
      // this.winBox4Edit.type = 'pool'

      let { mechanism, operator: operatorId } = this.$route.query

      let query = {
        mechanism,
        operatorId,
        box: 'template'
      }

      let [err, res] = await to(this.getList({ query: query }))

      if (err) {
        throw err
      }
      if (res) {
        this.templateList = _.cloneDeep(res.collection)
      }
      this.winBoxTemplateVisible = true
    },

    async onPoolUpdate () {
      if (!this.poolData4Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closePoolEditor('hold')
      }

      const keys = [
        ...this.winBoxPickedKeys
      ]

      const dataUpdate = _.pick(this.poolData4Update, keys)

      // 強轉數字類型
      this.parseObjectNumber(dataUpdate)

      dataUpdate.mechanism = this.mechanism

      const [err] = await to(this.updatePool({ id: this.poolData4Update.poolId, data: dataUpdate }))
      if (err) {
        return this.$Message.error(this.$t('Edit pool error'))
      }

      this.dataUpdate = dataUpdate

      this.$Message.success(this.$t('Edit pool seccess'))
      // this.findGame(data)
      this.closePoolEditor()
    },

    closePoolEditor (hold) {
      this.poolEditorVisible = false
      if (!hold) {
        this.poolData4Update = null
        this.onUpdate()
      }
      // setTimeout(() => {
      //   this.poolData4Update = null
      //   this.onUpdate()
      // }, 500)
    },

    // async getPoolTemplateList () {
    //   // this.poolData4Update = _.cloneDeep(this.formData)
    //   this.poolData4Update = {
    //     ...this.dataUpdate,
    //     ...this.poolData
    //   }

    //   let { mechanism, operator: operatorId } = this.$route.query

    //   let query = {
    //     mechanism,
    //     operatorId,
    //     box: 'rateMeta'
    //   }

    //   let [err, res] = await to(this.getList({ query: query }))

    //   if (err) {
    //     throw err
    //   }

    //   if (res) {
    //     this.rateMetaList = _.cloneDeep(res.collection)
    //   }
    //   this.poolTemplateVisible = true
    // },

    closePoolTemplate () {
      this.poolTemplateVisible = false
    },

    closePoolTemplateEdit () {
      this.poolTemplateEditVisible = false
    },
    openPoolTemplateEdit (row) {
      this.poolDataBag = _.cloneDeep(row)
      this.poolTemplateEditVisible = true
    },
    gobackPoolEditor (mode) {
      this.poolTemplateEditVisible = false

      if (mode === 'edit') {
        this.poolTemplateVisible = true
        // this.getPoolTemplateList()
      } else {
        this.poolEditorVisible = true
      }
    },
    templateUpdatePool (template) {
      _.forOwn(template, (value, key) => {
        if (_.has(this.poolData4Update, key)) {
          this.poolData4Update[key] = value
        }
      })
      this.onPoolUpdate()
    },
    poolCloseCheck () {
      this.poolCloseModal = true
    },
    async closeAllPools () {
      let operator = this.$route.query.operator

      if (operator === 'all' || !operator) return this.$Message.error(this.$t('Please select operator'))

      const [err] = await to(this.closeAllPool({ data: { 'operator': operator } }))
      if (err) {
        return this.$Message.error(this.$t('Edit pool error'))
      }

      this.$Message.success(this.$t('Edit pool seccess'))

      this.refresh(true)
      this.poolCloseModal = false
    }
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
.pull-right
  float: right
  margin-top: -4px
  button
    margin-left: 3px

@media (max-width: 767px)
  .info-card-body
    display: flex
    flex-direction: column
    & .card-cell
    width: 100%
  .app-info-card
    width: 94vw
    display: flex
    flex-direction: column

  .tip
    font-weight: 100

</style>
