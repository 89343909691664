<template lang="pug">
Modal(
    :mask-closable="true",
    :styles="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
)
  p(slot="header" :class="editType === 'edit' ? 'game-edit-title' : ''")
    span(v-if="editType === 'new'") {{ $t("Add new game") }}
    span.title(v-if="editType === 'edit'")
      .avatar-box(span="24")
        .game-edit-title(style="height: 40px; float: left;")
          Avatar(
            shape="square",
            size='large',
            :style="{marginRight: '8px'}",
            :src="getGameThumb(formData, loginUserInfo)",
            style="margin-right: 8px; float: left;"
          )
          .game-title(style="display: inline-grid; float: left;")
            span(style="font-size: 18px;") {{ formData.name }}
            span(style="font-size: 12px; font-weight: normal;") {{ formData.code }}

  Form.modal-editor(
    :model="formData"
    ref="gameEditorForm"
    :rules="gameEditorRule"
    )
    //- 新增用內容
    template(v-if="editType === 'new'")
      .content-part
        FormItem(prop="name")
          Row
            Col.title(span="9")
              h3 {{$t('Game name')}}
            Col.val(span="10" offset="5")
              Input(
                v-model="formData.name"
                :placeholder="$t('Input game name')"
              )
        FormItem(prop="code")
          Row
            Col.title(span="9")
              h3 {{$t('Game code')}}
            Col.val(span="10" offset="5")
              Input(
                v-model="formData.code"
                :placeholder="$t('Input game code')"
              )

        FormItem(prop="category")
          Row
            Col.title(span="9")
              h3 {{$t('Game category')}}
            Col(span="10" offset="5")
              Select(
                v-model="formData.category"
                :placeholder="$t('Select game category')"
              )
                Option(v-for="item in categoryList" :value="item.value" :key="item.value") {{ item.label }}

        FormItem(prop="mechanism")
          Row
            Col.title(span="9")
              h3 {{$t('Game mechanism')}}
            Col(span="10" offset="5")
              Select(
                v-model="formData.mechanism"
                :placeholder="$t('Select game mechanism')"
              )
                Option(v-for="item in mechanismList" :value="item.value" :key="item.value") {{ item.label }}

    //- 編輯用內容
    template(v-if="editType === 'edit'")
      .modal-editor
        .content-part
          Row
            Col(span="6")
              h3 {{$t('Game id')}}
            Col.val(span="18")
              span {{formData.gameId}}

          Row
            Col(span="4")
              h3 {{ $t('Game checksum') }}
            Col.val(span="20")
              span {{formData.checksum}}

          Row
            Col(span="6")
              h3 {{$t('Game code')}}
            Col.val(span="18")
              span {{formData.code}}

          Row
            Col(span="6")
              h3 {{$t('Game mechanism')}}
            Col.val(span="18")
              span {{ $t(formData.mechanism) }}

          Row
            Col(span="6")
              h3 {{$t('Game category')}}
            Col.val(span="18")
              span {{ $t(formData.category) }}

          Row
            Col(span="6")
              h3 {{$t('Game provider')}}
            Col.val(span="18")
              span {{formData.provider.name}}

          Row
            Col(span="6")
              h3 {{$t('Provider id')}}
            Col.val(span="18")
              span {{formData.provider.id}}

          Row
            Col(span="6")
              h3 {{$t('Game total in')}}
            Col.val(span="18")
              span {{currencyFormat(formData.totalIn, { noSymbol: true })}}

          Row
            Col(span="6")
              h3 {{$t('Game total out')}}
            Col.val(span="18")
              span {{currencyFormat(formData.totalOut, { noSymbol: true })}}

          Row
            Col(span="6")
              h3 {{$t('Current / Target RTP')}}
            Col.val(span="18")
              span.current-rtp {{ RTP }}
              span.rtp-divider /
              span.target-rtp {{ formData.config.GameRate * 100 }}%
          Row
            Col(span="6")
              h3 {{$t('Game level RTP')}}
            Col.val(span="18")
              Row
                .rtp-list(v-for="(item, key) in levelInOut" v-if="item.key !== 0")
                  Col(span="8")
                    span.stake-level {{ item.key.toFixed(2) }}
                    span.stake-rtp {{ item.value }}

        //- .content-part(v-if="isEditable")
        //-   Button(
        //-     @click="openSettings('game stake settings')"
        //-     v-if="!openedItem['game stake settings']"
        //-     style="right: 20px; position: absolute; z-index: 20;"
        //-     size="small"
        //-     type="success"
        //-     icon="ios-arrow-down"
        //-   )

        //-   Divider(orientation="left")
        //-     Icon(
        //-       type="md-create",
        //-       style="margin-right: 6px"
        //-     )
        //-     span {{$t('user-edit stake settings')}}

        //-   .settings(v-if="openedItem['game stake settings']")
        //-     FormItem(prop="ratioValues")
        //-       Row
        //-         Col.title(span="9")
        //-           h3 {{$t('Currency stage')}}

        //-         Col.val(span="15")
        //-           Row(:gutter="8")
        //-             Col.cell-col(
        //-               span="6"
        //-               v-for="(value, index) in formData.ratioValues"
        //-               :key="index"
        //-               :style="getOffset(formData.ratioValues, index)"
        //-             )
        //-               Input(
        //-                 v-model="formData.ratioValues[index]"
        //-               )
        //-                 span(slot="prepend") {{ index }}

        //-     FormItem(prop="stakeValues")
        //-       Row
        //-         Col.title(span="9")
        //-           h3 {{$t('Game betting stage')}}

        //-         Col.val(span="15")
        //-           Row(:gutter="8")
        //-             Col.cell-col(
        //-               span="6"
        //-               v-for="(value, index) in formData.stakeValues"
        //-               :key="index"
        //-               :style="getOffset(formData.stakeValues, index)"
        //-             )
        //-               Input(
        //-                 v-model="formData.stakeValues[index]"
        //-               )
        //-                 span(slot="prepend") {{ index }}

        .content-part(v-if="isEditable")
          Button(
            @click="openSettings('Game level rate settings')"
            v-if="!openedItem['Game level rate settings']"
            style="right: 20px; position: absolute; z-index: 20;"
            size="small"
            type="success"
            icon="ios-arrow-down"
          )

          Divider(orientation="left")
            Icon(
              type="md-analytics",
              style="margin-right: 6px"
            )
            span {{ $t("Game level rate settings") }}

          .settings(v-if="openedItem['Game level rate settings']")
            FormItem(prop="mgGroupBoxChoiceVal")
              Row
                  Col.title(span="9")
                    h3 {{ $t("Average level rate") }}
                  Col.val.string(span="8" offset="7")
                    span {{ averageLevelRate() }}

              Row(v-for="key in levelKeys" :key="key")
                  Col.title(span="7")
                    h3
                      .mg-box-con
                        icon(type="logo-usd" size="small" style='margin: 0 4px')
                        span {{ key.replace('-', '.') }}
                      .mg-box-con
                        icon(type="md-analytics" size="small" style='margin: 0 4px 0 8px')
                        span.text-red {{ renderRtp(formData.config.levelInOut[key].mgGroupBoxChoiceVal) }}

                  //- template(v-if="!isArray(config)")
                  //-   Col.val.string(span="10" offset="7")
                  //-     Input(
                  //-       v-model="formData.config.levelInOut[key].mgGroupBoxChoiceVal"
                  //-       :number="true"
                  //-     )

                  template
                    Col.val(span="17")
                      Button(v-if="!openedItem[key]" @click="openConfig(key)" size="small" type="success" icon="md-create")
                      Row(:gutter="8" v-if="openedItem[key]")
                        Col.text-left(span="24" style="padding-left: 0;")
                          h4 {{ $t("mgGroupBoxChoiceVal") }}
                        Col.margin-bottom-6(
                          span="6"
                          v-for="(value, index) in formData.config.levelInOut[key].mgGroupBoxChoiceVal"
                          :style="getOffset(formData.config.levelInOut[key].mgGroupBoxChoiceVal, index)"
                          :key="index"
                        )
                          Input(
                            v-model="formData.config.levelInOut[key].mgGroupBoxChoiceVal[index]"
                          )
                            span(slot="prepend") {{ mgGroupBoxRate[index] }}

                        .box-rate-alert(v-if="levelChanceSum(formData.config.levelInOut[key].mgGroupBoxChoiceVal) !== 1000")
                          icon(type="md-warning" style="margin-right: 6px")
                          span {{ $t('Level chance set to {value}, total value must equal to 1000', { value: levelChanceSum(formData.config.levelInOut[key].mgGroupBoxChoiceVal) }) }}

                        Row(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('mgWinTolerance') }}
                          Col(span="6")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].mgWinTolerance"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('fgEnterRateGroupVal') }}
                          Col(span="6")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].fgEnterRateGroupVal[0]"
                              @input="onFgEnterRateChange(key)"
                            )
                              span(slot="append") {{ formData.config.levelInOut[key].fgEnterRateGroupVal[1] }}

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('fgWinTolerance') }}
                          Col(span="6")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].fgWinTolerance"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('bonusNormalSpinRange') }}
                          Col(span="3" style="padding-right: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusNormalSpinRange[0]"
                            )
                          Col(span="3" style="padding-left: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusNormalSpinRange[1]"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('bonusFreeLimit') }}
                          Col(span="6")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusFreeLimit"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('bonusSpinRange') }}
                          Col(span="3" style="padding-right: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusSpinRange[0]"
                            )
                          Col(span="3" style="padding-left: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusSpinRange[1]"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('bonusTimeRange') }}
                          Col(span="3" style="padding-right: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusTimeRange[0]"
                            )
                          Col(span="3" style="padding-left: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusTimeRange[1]"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('bonusFgRange') }}
                          Col(span="3" style="padding-right: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusFgRange[0]"
                            )
                          Col(span="3" style="padding-left: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].bonusFgRange[1]"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('mgWinToleranceBonus') }}
                          Col(span="3" style="padding-right: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].mgWinToleranceBonus[0]"
                            )
                          Col(span="3" style="padding-left: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].mgWinToleranceBonus[1]"
                            )

                        Row.margin-top-6(:gutter="8")
                          Col.text-left(span="18")
                            h4 {{ $t('fgWinToleranceBonus') }}
                          Col(span="3" style="padding-right: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].fgWinToleranceBonus[0]"
                            )
                          Col(span="3" style="padding-left: 0;")
                            Input.chance-input(
                              v-model="formData.config.levelInOut[key].fgWinToleranceBonus[1]"
                            )

        .content-part(v-if="isEditable")
          Button(
            @click="openSettings('game settings')"
            v-if="!openedItem['game settings']"
            style="right: 20px; position: absolute; z-index: 20;"
            size="small"
            type="success"
            icon="ios-arrow-down"
          )

          Divider(orientation="left")
            Icon(
              type="ios-color-fill",
              style="margin-right: 6px"
            )
            span {{ $t("Game config") }}

          .settings(v-if="openedItem['game settings']")
            FormItem(prop="config")
              Row(v-for="(config, key) in formData.config" :key="key" v-if="!/comment/.test(key)")
                  Col.title(span="9")
                    h3 {{ key }}
                  template(v-if="!isArray(config)")
                    Col.val.string(span="8" offset="7")
                      Input(
                        v-model="formData.config[key]"
                        :number="true"
                      )

                  template(v-if="isArray(config)")
                    Col.val(span="15")
                      Button(v-if="!openedItem[key]" @click="openConfig(key)" size="small" type="success" icon="md-create")

                      Row(:gutter="8" v-if="openedItem[key]")
                        Col.cell-col(
                          span="6"
                          v-for="(value, index) in formData.config[key]"
                          :style="getOffset(formData.config[key], index)"
                          :key="index"
                        )
                          Input(
                            v-model="formData.config[key][index]"
                          )
                            span(slot="prepend") {{ index }}

  .footer(slot='footer')
    Button(icon="md-close" @click="onClose" :disabled="isUpdating") {{$t('Cancel')}}
    Button(
      icon="md-checkmark"
      type="primary"
      @click="handleSubmit"
      :loading="isUpdating"
      v-if="isEditable"
      ) {{$t('Confirm')}}

</template>
<script>
import Thumbnail from '~m/thumbnail'
import Currency from '~m/currency'
import NumberUtil from '~m/number-util'

export default {
  name: 'game-editor',
  mixins: [Thumbnail, Currency, NumberUtil],
  data () {
    return {
      mgGroupBoxRate: [0.2, 0.45, 0.7, 0.98, 1.07],
      isUpdating: false,
      openedItem: {},
      mechanismObject: {
        slot: [
          {
            label: this.$t('slot1'),
            value: 'slot1'
          },
          {
            label: this.$t('slot2'),
            value: 'slot2'
          },
          {
            label: this.$t('slot3'),
            value: 'slot3'
          }
        ],
        poker: [
          {
            label: this.$t('Banker bull bull'),
            value: 'oxoxdealer'
          },
          {
            label: this.$t('Golden flower'),
            value: 'goldenFlower'
          },
          {
            label: this.$t('Casino ox ox'),
            value: 'casinoOxOx'
          },
          {
            label: this.$t('Banker ox ox'),
            value: 'bankerOxOx'
          },
          {
            label: this.$t('Three face'),
            value: 'threeFaceCard'
          },
          {
            label: this.$t('Black jack'),
            value: 'blackJack'
          },
          {
            label: this.$t('Rich man'),
            value: 'richman'
          }
        ]
      },
      categoryList: [
        {
          label: this.$t('Slot game'),
          value: 'slot'
        },
        {
          label: this.$t('Poker game'),
          value: 'poker'
        }
      ]
    }
  },
  props: {
    formData: Object,
    loginUserInfo: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) {
          this.openedItem = {}
          return
        }
        this.parseObjectNumber(this.formData)
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          // console.log(`update isDirty`)
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    isEditable () {
      if (!this.formData) return false
      return this.loginUserInfo.isAdministrator && this.formData.category === 'slot'
    },
    levelKeys () {
      let keys = Object.keys(this.formData.config.levelInOut)
      keys.sort((a, b) => {
        return Number(a.replace('-', '.')) - Number(b.replace('-', '.'))
      })
      return keys
    },
    gameEditorRule () {
      switch (this.editType) {
        case 'new':
          return {
            name: [{ required: true, message: this.$t(`Please input game name`), trigger: 'blur' }],
            code: [{ required: true, validator: this.validateCode, trigger: 'blur' }],
            category: [{ required: true, message: this.$t(`Please choose game category`), trigger: 'blur' }],
            mechanism: [{ required: true, message: this.$t(`Please choose game mechanism`), trigger: 'blur' }]
          }
        case 'edit':
          return {
            name: [{ required: true, message: this.$t(`Please input game name`), trigger: 'blur' }],
            code: [{ required: true, validator: this.validateCode, trigger: 'blur' }],
            category: [{ required: true, message: this.$t(`Please choose game category`), trigger: 'blur' }],
            mechanism: [{ required: true, message: this.$t(`Please choose game mechanism`), trigger: 'blur' }]
          }
        default:
          return {}
      }
    },
    levelInOut () {
      if (!this.formData.levelInOut) return []
      let keys = Object.keys(this.formData.levelInOut)
      keys = keys.map(k => {
        let { totalOut, totalIn, gameRate } = this.formData.levelInOut[k]
        gameRate = `${(gameRate * 100).toFixed(2)}%`
        const key = k.replace('-', '.') * 1
        const value = `${(totalOut / totalIn * 100).toFixed(2)}%`
        return { key, value, gameRate }
      })
      keys = _.sortBy(keys, ['key'])
      return keys
    },
    mechanismList () {
      return this.mechanismObject[this.formData.category]
    },
    RTP () {
      const { totalIn, totalOut } = this.formData
      return `${_.floor(totalOut / totalIn * 100, 2)}%`
    }
  },
  methods: {
    onFgEnterRateChange (key) {
      let { fgEnterRateGroupVal } = this.formData.config.levelInOut[key]
      this.formData.config.levelInOut[key].fgEnterRateGroupVal[1] = 1000 - fgEnterRateGroupVal[0]
    },
    levelChanceSum (settings) {
      if (!_.isArray(settings)) return false
      let val = 0
      for (let i = 0; i < settings.length; i++) {
        val += settings[i]
      }
      return val
    },
    averageLevelRate () {
      let settings = this.formData.config.levelInOut
      let average = 0
      let keys = Object.keys(settings)
      keys.forEach(k => {
        average += Number(this.renderRtp(settings[k].mgGroupBoxChoiceVal))
      })
      return (average / keys.length).toFixed(2)
    },
    renderRtp (settings) {
      if (!_.isArray(settings)) return 0
      let rtp = 0
      for (let i = 0; i < settings.length; i++) {
        let val = settings[i] * this.mgGroupBoxRate[i] / 1000
        rtp += val
      }
      return rtp.toFixed(2)
    },
    validateCode (rule, value, callback) {
      if (value === '') {
        callback(new Error(this.$t(`Please input game code`)))
      } else if (value.length < 4) {
        callback(new Error(this.$t('Game code is shorter than the minimum allowed length (4)')))
      } else if (!/^[a-zA-Z0-9_-]{4,30}$/.test(value)) {
        callback(new Error(this.$t(`Game code is only allowed "A-Z", "a-z" and "-/_" characters`)))
      } else {
        callback()
      }
    },
    getOffset (data, index) {
      let offset = null
      const length = data.length - index
      if (index % 4 === 0 && length < 4) {
        offset = {
          marginLeft: `${(4 - length) % 4 * 6 / 24 * 100}%`
        }
      }
      return offset
    },

    isArray (data) {
      return _.isArray(data)
    },

    openSettings (name) {
      this.openedItem[name] = true
      this.$forceUpdate()
    },

    openConfig (key) {
      this.openedItem[key] = true
      this.$forceUpdate()
    },

    async handleSubmit () {
      let valid = await this.$refs.gameEditorForm.validate()
      if (!valid) {
        return false
      }
      this.isUpdating = true
      this.onUpdate()
    },

    visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.onClose()
      }

      this.formData.isReady = true
    }
  }
}
</script>
<style lang="sass" scoped>
.game-edit-title
  height: 40px
  p
    height: auto

.rtp-list
  // padding: 4px 12px
  // width: auto
  // float: left
  .stake-level
    font-weight: bold
    margin-right: 8px
    width: 40px
    text-align: right
    display: inline-block

  .stake-rtp
    width: 48px
    text-align: right
    display: inline-block

.current-rtp, .text-red
  // font-weight: bold
  color: #ed4014

.box-rate-alert
  font-weight: bold
  color: #ed4014
  text-align: right

.target-rtp
  color: #c5c8ce

.rtp-divider
  margin: 0 4px

.pull-right
  float: right

.text-left
  text-align: left

.chance-input
  text-align: right
  padding-right: 4px

.margin-top-6
  margin-top: 6px

.margin-bottom-6
  margin-bottom: 6px

.mg-box-con
  width: 65px
  float: left

</style>
