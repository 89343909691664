<script>
import { Table } from 'view-design'

export default {
  name: 'AppTable',
  mixins: [Table],
  methods: {
    toggleExpand (_index) {
      let data = {}
      for (let i in this.objData) {
        if (parseInt(i) === _index) {
          data = this.objData[i]
        } else {
          this.objData[i]._isExpanded = false
        }
      }

      setTimeout(() => {
        const status = !data._isExpanded
        this.objData[_index]._isExpanded = status
        this.$emit('on-expand', JSON.parse(JSON.stringify(this.cloneData[_index])), status)
        if (this.height || this.maxHeight) {
          this.$nextTick(() => this.fixedBody())
        }
      }, 50)
    }
  }
}

</script>
