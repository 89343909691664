<script>
// 解析縮圖資料 base url 來源自 .env
export default {
  data () {
    return {
      currencyOpts: {}
    }
  },
  methods: {
    // 取得盤面 icon
    parseObjectNumber (object) {
      _.forEach(object, (value, key) => {
        if (_.isObject(value)) {
          this.parseObjectNumber(value)
        } else if (/^-?[0-9.]+$/.test(value)) {
          object[key] = value * 1
        }
      })
    },

    // 判斷是不是數字
    isNumber (string) {
      return /^-?[0-9.]+$/.test(string)
    },

    // 加上逗號分隔的數字
    numberWithCommas (x, digits = 0) {
      if (!x) return '0'
      let local = this.currencyOpts.locale || 'zh-tw'
      const opts = {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits
      }
      return new Intl.NumberFormat(local, opts).format(x)
    }
  }
}
</script>
